import React from 'react'
import Layout from '../../components/layout'
import { graphql } from "gatsby"
import { Link } from 'gatsby'
import episodeArt from '../../images/song_art/episode6.png'

const Episode5 = () => (
  <Layout>
    <section className="hero is-primary is-primary">
      <div className="hero-body has-bg-img secondary">
        <div className="container has-text-centered">
          <span className="subtitle">afraid debbie downer may be right this time</span>
        </div>
      </div>
    </section>
    <section className="section">
      <div className="container podcast is-fluid">
        <div className="columns name">
          <div className="column">
            <h1 className="is-size-4">Episode 6: Now it grows colder</h1>
          </div>
        </div>
        <div className="columns">
          <div className="column is-one-third">
            <img src={episodeArt} className="episode-art" alt="Snowy landscape (with overlay: 'now it grows colder'" />
            <iframe style={{border: 'none'}} src="//html5-player.libsyn.com/embed/episode/id/8101526/height/50/theme/legacy/thumbnail/no/preload/no/direction/backward/" height="50" width="100%" scrolling="no"></iframe>
          </div>
          <div className="column is-two-thirds transcript">
            <p>
              Welcome back to Planet and Sky, a cosmic love story. Our tale began with a scientific expedition to a remote and barren planet. Scientists researching there found the residue of an ancient consciousness – the consciousness of the planet itself. And the planet held memories of a time in the distant past when it had been one with a beautiful and lush atmosphere.  In our previous episode, Such is Love, Planet’s ode to his love for Sky was interrupted briefly by Sky’s forboding concerns about the unforgiving universe and her concern that it might eventually find a way to end their marital bliss. In this episode, we will see her darkest fears begin to appear prescient, but the story of this change in the weather will be told by our strange trio of characters from far beyond.
            </p>

            <p>
              Earlier, three individuals had started out on a rescue mission in search of two lost scientists failing to report back from a mission of exploration. After their mysterious transformation into three primitive life forms within an icy rock in deep space, their interstellar odyssey had terminated with a plunge into a new star system and a fiery entry into an unsuspecting planet.
            </p>

            <p>
              Despite the intense heat and pressure from the blast, enough of the key ingredients of life remained within what was left of the icy world. As Planet shifted his weight and Sky strengthened and unleashed her stormy winds and rain, the travelers were uncovered and found refuge within the warm still waters upon the surface. It was here that the children of Planet and Sky were conceived and flourished.
            </p>

            <p>
              The first traveler became extended throughout the plant and animal kingdom as the root of the will to live and evolve, to begin the long road of natural selection toward creatures like that ambitious ape we met previously reclining upon his lakeside rock.
            </p>

            <p>
              The second traveler, after being tremendously surprised at surviving the fiery plunge into the unknown world, was now represented in the vast assemblage of creatures nervously on guard for the panoply of dangers sprinkled throughout her adventurous world. As the graceful and delicate mammals grazing among the grasses alert for dangers in every direction and the birds startled into flight at the sound of intruders, and in many other forms, it guided life to safety and kept it from blundering to extinction.
            </p>

            <p>
              Finally, the third traveler kept calm and carried on. It found ways to harmonize with whatever environments it encountered, happily swimming in the deep waters, crawling upon the varied terrain, and cruising the gentle airways in search of food and relaxing sanctuaries where it could make the best of this amazing property of existence shared by all living things.
            </p>

            <p>
              After eons of struggle and victory to survive and thrive in this world, the descendants of the intruding microbes had enjoyed a long stretch of prosperous life and a mostly benevolent climate. Now, however, something was changing.
            </p>

            <p>
              The weather had taken a turn for the worse. The sunny warm days were becoming fewer in frequency and some nasty cold snaps had grown distressingly more frequent. Ice began accumulating in the northern and southern regions and on the frigid peaks. Glaciers began to expand and move into areas that had formerly been warm and suitable for life. Deserts were expanding as well. It was starting to look like a long term shift was occurring in the overall climate.
            </p>

            <p>
              The first traveler felt a need to counter any concerns about these developments.
            </p>

            <p>
              It gave voice to this perspective, “Beautiful life, we’ve found our home, never again will we worry or want or need.”
            </p>

            <p>
              The second traveler, as expected, had a somewhat opposing view. It felt sure, as always, that any tendencies towards good fortune in this world were likely to be fleeting at best and that a turn for the worst was always highly likely. The recent changes were only confirmation of its chronic suspicions.
            </p>

            <p>
              It countered, “Respite is now but disquiet lingers. Questions arising disturbing the tremulous peace.”
            </p>

            <p>
              But the voice of contentment and acceptance arose from the third traveler. It maintained a typically matter of fact perspective.
            </p>

            <p>
              “First we were favored,” it said with a shrug, “and now it grows colder. We do what we must to exist in a changing world.”
            </p>

            <p>
              The first traveler was not easily swayed. Hadn’t it been correct in its insistence that their hurtling meteor would have a fortuitous arrival in an eventual safe abode? It was not about to start accepting the nattering negativity from the fearful one at this point.
            </p>

            <p>
              “There are no limits,” it sang, “no reason for doubting. Our world is our destiny brought to us, now we are free.”
            </p>

            <p>
              But the second traveler remained adamant that its steady optimism was this time in error. It pointed out the sweeping changes underway, highlighting the persistent lack of warmth that could only be a sign of a dire change for the worse.
            </p>

            <p>
              “Haven’t you noticed a change in the weather?” it warned. “The days become colder, the nights make us shiver and moan!”
            </p>

            <p>
              The third traveler pointed out that there were pleasures to be found regardless of the twists and turns of fortune. It busied itself with the sorts of actions that made sense at a time like this. Dig in and ride it out, it thought. There are surely pleasures to be found in all changes.
            </p>

            <p>
              “Deeper is warmer, always an answer,” it thought. “I see a rock with some speckles, nice colors and shapes.”
            </p>

            <p>
              As the darkness descended and the temperature plunged to an unbearable degree, the first traveler held hope that a new day would save them in due course. Yes, it was undeniably becoming uncharacteristically cold and dry, but surely this was just a brief interruption on their path to a sustained and glorious future.
            </p>

            <p>
              “Cycles come often,” it said, “await the fine summers. The chill makes us stronger. We build and with joy we will live.”
            </p>

            <p>
              The second traveler unsurprisingly disagreed. It had been wrong before in predicting doom, but it knew they had been very lucky. Confident in its assessment of the terminal conditions facing all of the living creatures in this ever-frightening world, it predicted great misfortune.
            </p>

            <p>
              “Not this time my friend,” it sighed, “it worsens by season, our world is in crisis, our days of fair glory behind.”
            </p>

            <p>
              Burrowing deep into the depths of Planet’s deep nooks and crevices, where trickles of diminishing moisture and weak pockets of warmth tentatively remained, the third traveler croaked out a last morsel of wisdom before the final movements of all were stilled. As always, it  was philosophical and straightforward about the situation.

            </p>

            <p>
              “Time slows and crawls,” it sang, “each second a journey, the moments extended and rich with the essence of time.”
            </p>

            <p>
              Planet felt the increasing cold and dry conditions and was disturbed at the trend and its effect upon their children. He had always been patient, and his tendency was toward optimism. Surely he and Sky were only enduring a brief hiatus in their endless journey. A new dawn would surely arise.
            </p>

            <p>
              High overhead, Sky prepared to impart the sad news to him in a woeful tune she was even now composing. The news she would share would break his heart as it had already broken hers. She would have to find a way to impart a truth so dark such that Planet could scarcely fathom.
            </p>
          </div>
        </div>
      </div>
  </section>
  </Layout>
)

export default Episode5
